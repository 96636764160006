.app-footer {
  background-color: green;
  bottom: 0;
  height: 50px;
  position: absolute;
  width: 100%;
  display: none;
}

@media screen and (min-width: 768px) {
  .app-footer {
    height: 100px;
  }
}
