body {
  background-color: #333333;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1200px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 6, 2018 */
@font-face {
    font-family: 'Red October Regular';
    src: url(/static/media/red_october-webfont.bd7e3a95.woff2) format('woff2'),
         url(/static/media/red_october-webfont.5fcb7ea8.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

.app-header {
  background-color: #683833;
  background-color: #333333;
  min-width: 50px;
  width: 100%;
  border-bottom: 0px solid #4f2f2c;
}

.app-header .wrapper {
  background-image: url(/static/media/header-bg-mobile.323e0f98.png);
  background-position: right;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,.75);
}

.app-header .header-wrapper {
  height: auto;
  position: relative;
  transition: all 0.2s linear;
  position: relative;
  z-index: 99;
}

.app-header .header-pabrick {
  background-image: url(/static/media/pabrick.7f5e7d24.png);
  background-size: contain;
  display: block;
  height: 50px;
  width: 50px;
  left: -50px;
  bottom: 0;
  position: absolute;
  z-index: 100;
  transition: all 0.2s linear;
}

.app-header h1 {
  color: #FEFEFE;
  display: block;
  font-family: 'Red October Regular';
  font-size: 40px;
  margin: 0 auto 0 20px;
  min-height: 50px;
  text-align: left;
  text-shadow: 4px 4px rgba(0,0,0,.5);
  width: 260px;
  transition: all 0.2s linear;
}
.app-header h1 span {
  display: inline-block;
}
.app-header h1 .h1-rotation {
  display: inline-block;
  font-size: 12px;
  left: -8px;
  position: relative;
  text-shadow: -2px 2px rgba(0,0,0,.5);
  top: -11px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

@media screen and (min-width: 700px) {
  .app-header .wrapper {
    background-image: url(/static/media/header-bg.21a278c1.png);
    background-position: -80px;
  }
  .app-header .header-pabrick {
    height: 374px;
    width: 374px;
    left: 0;
  }
  .app-header h1 {
    font-size: 150px;
    margin: 0 40px 0 auto;
    text-align: right;
    text-shadow: 10px 10px rgba(0,0,0,.5);
    width: 500px;
  }
  .app-header h1 span {
    position: relative;
    text-align: right;
  }
  .app-header h1 .h1-aky {
    margin-right: 60px;
  }
  .app-header h1 .h1-rotation {
    display: table;
    float: right;
    font-size: 46px;
    left: 326px;
    position: absolute;
    text-shadow: -10px 10px rgba(0,0,0,.5);
    top: 68px;
  }
}

@media screen and (min-width: 960px) {
  .app-header .wrapper {
    background-position: 0;
  }
  .app-header .header-pabrick {
    height: 496px;
    width: 496px;
  }
  .app-header h1 {
    font-size: 200px;
    width: 635px;
  }
  .app-header h1 .h1-rotation {
    font-size: 60px;
    left: 439px;
    top: 89px;
  }
}

.text-gradient {
  /* COLOR GRADIENT */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,dddddd+100 */
  background: #ffffff; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%,#dddddd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=0 ); /* IE6-9 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app-header .header-bt-menu {
  background-color: #FFFFFF;
  border: 4px solid #CD2C1C;
  border-radius: 0;
  box-shadow: 4px 4px rgba(0,0,0,.5);
  cursor: pointer;
  display: block;
  float: right;
  height: 40px;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 5px;
  width: 40px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .5s ease-in-out;
}

.app-header .header-bt-menu span {
  display: block;
  position: absolute;
  height: 5px;
  width: 26px;
  background-color: #CD2C1C;
  border-radius: 15px;
  opacity: 1;
  left: 3px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.app-header .header-bt-menu span:nth-child(1) { top: 5px; }
.app-header .header-bt-menu span:nth-child(2),
.app-header .header-bt-menu span:nth-child(3) { top: 14px; }
.app-header .header-bt-menu span:nth-child(4) { top: 23px; }

.app-header .header-bt-menu.open span:nth-child(1),
.app-header .header-bt-menu.open span:nth-child(4) { left: 50%; top: 18px; width: 0%; }

.app-header .header-bt-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.app-header .header-bt-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (min-width: 700px) {
  .app-header .header-bt-menu {
    display: none;
  }
}

.app-header .overlay {
  background-color: rgba(0,0,0,.5);
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.app-header .overlay.close { top: -100%; }
.app-header .overlay.open { top: 0%; }

.app-header .overlay nav {
  display: table-cell;
  vertical-align: middle;
}

.app-header .overlay .menu-bt {
  background-color: #CD2C1C;
  box-shadow: 10px 10px rgba(0,0,0,.5);
  border-width: 0;
  cursor: pointer;
  display: block;
  height: 50px;
  width: 70%;
  max-width: 280px;
  margin: 40px auto;
}

.app-header .overlay .menu-bt span {
  display: table-cell;
  color: #FEFEFE;
  font-family: 'Red October Regular';
  font-size: 32px;
}

.app-header .overlay .menu-bt span::before {
  background-size: contain;
  border: 3px solid #FFFFFF;
  content: '';
  display: table-cell;
  margin-right: 10px;
  height: 34px;
  width: 34px;
  float: left;
}

.app-header .overlay .bt-history span::before { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAA0klEQVRYhe3VPQrCQBBA4bdqI9oJ1vEMniadV/BGHiUX8AJWimBvYT02KUTJ7iyzCYvMBwFJhuSx+RGcc1FBMyQiW2AL7IA18Ch0/WsI4RYbWKTOICIt0H7tPluqPnRANHAWOygiK37jJhUNDCG8pgoZkrzFCgegyZjv+k0luoI1KLGCpwLnGFT9ClYf6C+Jlb8kVv4MWvkzaOWBVh5o5YFW1QfmfKiXwLz/vS90/UtqQBO4Ie+/NkeXGtDc4sacYaAJfI5eEaEJPAL3sUOcc//qDaAKHROTXqcBAAAAAElFTkSuQmCC); }
.app-header .overlay .bt-timeline span::before { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAACWklEQVRYhe2XLXPbQBCGnysyq6FYAs0aGBaXFbosMDCs6S+of0JgmQ3DahgWlQUaGrrMrC4z24Ldq2VbJ92dNZ1pR++MR9rTae/1aj+hR48e/zbcuQpE5AYYBx6Xzrnv556RDBG5EZFvEoefIjITkYucs5IsKCJvgTkwqSyv7VeHEVBU5Efn3OeUM6MJisg7oASGtlQCS2Bb2XZp13VlbYi6wJXJS2DsnPvVGUGz3NIIbIEnYFOzdXp0raIAbo3wwjn3MebsN5Hkygq5rwFybdjYu1tgIiKzTgii1rgCdqjldhnkPKo67kTk01kEze8eTAx91lRsgIXdP7ZFd5sFPbklp44/ymFnWJlOqPfXPwgSNN+7M7E8ejxBHf5DDrsjnRM7qxZNFvS5bsVhKgF4trVr9E8MMghu0c895DCvRhP0eavO73xErtHovucwIcdidXTWCWIIrgPPd2hVeUWtcJ/G7UB3FsFYPHN++gmiC4Kgn2pON2noADEEYwNgg5JctexL0t1EsLRrivP7ShELr7sMbWgi6BNpTnTGwutehjbEWHDEvsXqEtVqVIY2BQlavzY3cdwRqSq8znlTb9jYD1ohX5uY22bVoWCfNy+dcz9CGxuj2F6cmnhLXkk7xsB0AUybyEF8R/2CfhLfKuVaskDrboFOfO/bXkhp+RdG0pe4VJIF+8Yiei5Jnepm7FuwVzT62krcAP1j1ybPgYdOh6YqROQLe7/coZVjxSnRAZpGRna/RSM2aezMgg3us8jBXUTk5a8M7jVEL1CnDzWcJWq1xkjt0aPH/4zfEFgPzoChUwUAAAAASUVORK5CYII=); }
.app-header .overlay .bt-badges span::before { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABJElEQVRYhe3SP0oDQRTH8e+zDqTwAFrZ5gjiSVJ6AMtcQYi1feocwdgreoNgHwjiAX4WWoQlZN/MzptVmC9ssez8+bA8aLVa/zvrWyDpGtgUum8HfBy835rZ66kNZ4Uu9tTFuaoFzMJBHWA2DuKBg3AQCxyMgzigB3cOTPoOigB6cZeew0oDi+KgLLA4DsoBQ3BQBhiGg+HAUBwMA4bjIB9YBQd5wGo4SAdWxUEasDoO/MBRcOAAmtkzsOhZloPbAO99i1x/0MwegTvg68jnHNyDmd2Y2WfivtNJupK0kvTy+2yV1l7SvCjqCHIi6T4Dt5U0C8V1oMsE3JOkaTXcAXLuwC2rwzrImX5mq1v8vHmTdCHpbbR58yRpKmk92ry1Wq0/2jeSwk5rCq4CHgAAAABJRU5ErkJggg==); }
.app-header .overlay .bt-contact span::before { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABaUlEQVRYhe2WPU7DQBCF31KmiDlAcoIUSQk0oQtdKmjTpM+RfBRTUtJT5ATkjybdo1lLyEqsfbPeBSF/kht7dubzeH8M9PT09CTFdZGEZAFg1rj97pw7dpHfDMklyYrXqUguf0uubBFrUuaWWwlyNatcclODXM00h2AVIVillovpnqmLN6LjoxgfnUMVvBXjo3OogtlRBQ8d1JRyqIKvYnyqHNchuY1Ywdukcl5wHiE4Ty7oJUuDXPbzeENyHyC2J7nJKucFhyQ/SJ5b5M4+ZmitE7MPrgEMAHwC2AH4alw7/2zgY02Y/6hJvgEYB4YfAdw5505qHVMHST4jXA4ACgAvllrWT2wpZvrMsiDJEYAHQ60xyXt1kKWDT4YxNXLnLYKmueRZqAMkQb+fTdQiPyhISpJqB+UOXECah6qgZXFE5VAF5VV4gYly9AUL+u1F2ZzbCH5RpYMxi8Oc618JdjH/aoKnyp/v4Dfzx5PBav44ygAAAABJRU5ErkJggg==); }

@media screen and (min-width: 700px) {
  .app-header .overlay {
    display: none;
  }
}
.app-footer {
  background-color: green;
  bottom: 0;
  height: 50px;
  position: absolute;
  width: 100%;
  display: none;
}

@media screen and (min-width: 768px) {
  .app-footer {
    height: 100px;
  }
}

