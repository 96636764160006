@import url("./../assets/fonts/red_october/red_october.css");

.app-header {
  background-color: #683833;
  background-color: #333333;
  min-width: 50px;
  width: 100%;
  border-bottom: 0px solid #4f2f2c;
}

.app-header .wrapper {
  background-image: url('./../assets/images/header-bg-mobile.png');
  background-position: right;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,.75);
  -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,.75);
  box-shadow: 0px 0px 50px 0px rgba(0,0,0,.75);
}

.app-header .header-wrapper {
  height: auto;
  position: relative;
  transition: all 0.2s linear;
  position: relative;
  z-index: 99;
}

.app-header .header-pabrick {
  background-image: url('./../assets/images/pabrick.png');
  background-size: contain;
  display: block;
  height: 50px;
  width: 50px;
  left: -50px;
  bottom: 0;
  position: absolute;
  z-index: 100;
  transition: all 0.2s linear;
}

.app-header h1 {
  color: #FEFEFE;
  display: block;
  font-family: 'Red October Regular';
  font-size: 40px;
  margin: 0 auto 0 20px;
  min-height: 50px;
  text-align: left;
  text-shadow: 4px 4px rgba(0,0,0,.5);
  width: 260px;
  transition: all 0.2s linear;
}
.app-header h1 span {
  display: inline-block;
}
.app-header h1 .h1-rotation {
  display: inline-block;
  font-size: 12px;
  left: -8px;
  position: relative;
  text-shadow: -2px 2px rgba(0,0,0,.5);
  top: -11px;
  transform: rotate(-90deg);
}

@media screen and (min-width: 700px) {
  .app-header .wrapper {
    background-image: url('./../assets/images/header-bg.png');
    background-position: -80px;
  }
  .app-header .header-pabrick {
    height: 374px;
    width: 374px;
    left: 0;
  }
  .app-header h1 {
    font-size: 150px;
    margin: 0 40px 0 auto;
    text-align: right;
    text-shadow: 10px 10px rgba(0,0,0,.5);
    width: 500px;
  }
  .app-header h1 span {
    position: relative;
    text-align: right;
  }
  .app-header h1 .h1-aky {
    margin-right: 60px;
  }
  .app-header h1 .h1-rotation {
    display: table;
    float: right;
    font-size: 46px;
    left: 326px;
    position: absolute;
    text-shadow: -10px 10px rgba(0,0,0,.5);
    top: 68px;
  }
}

@media screen and (min-width: 960px) {
  .app-header .wrapper {
    background-position: 0;
  }
  .app-header .header-pabrick {
    height: 496px;
    width: 496px;
  }
  .app-header h1 {
    font-size: 200px;
    width: 635px;
  }
  .app-header h1 .h1-rotation {
    font-size: 60px;
    left: 439px;
    top: 89px;
  }
}

.text-gradient {
  /* COLOR GRADIENT */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,dddddd+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top, #ffffff 0%, #dddddd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffffff 0%,#dddddd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%,#dddddd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#dddddd',GradientType=0 ); /* IE6-9 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
