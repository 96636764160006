.app-header .header-bt-menu {
  background-color: #FFFFFF;
  border: 4px solid #CD2C1C;
  border-radius: 0;
  box-shadow: 4px 4px rgba(0,0,0,.5);
  cursor: pointer;
  display: block;
  float: right;
  height: 40px;
  outline: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 5px;
  width: 40px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.app-header .header-bt-menu span {
  display: block;
  position: absolute;
  height: 5px;
  width: 26px;
  background-color: #CD2C1C;
  border-radius: 15px;
  opacity: 1;
  left: 3px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.app-header .header-bt-menu span:nth-child(1) { top: 5px; }
.app-header .header-bt-menu span:nth-child(2),
.app-header .header-bt-menu span:nth-child(3) { top: 14px; }
.app-header .header-bt-menu span:nth-child(4) { top: 23px; }

.app-header .header-bt-menu.open span:nth-child(1),
.app-header .header-bt-menu.open span:nth-child(4) { left: 50%; top: 18px; width: 0%; }

.app-header .header-bt-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.app-header .header-bt-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (min-width: 700px) {
  .app-header .header-bt-menu {
    display: none;
  }
}
